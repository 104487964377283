
.fancybox-overlay {
    background: rgba(0,0,0,.5);
}
#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background: transparent;
}
#fancybox-loading div {
    background: transparent;
}
.fancybox-nav {
    background: transparent;
}

.fancybox-skin {
    background:none;
}
.bind-fancybox {
    .fancybox-close {
        position: fixed;
        top: 40px;
        right: 50px;

        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #fff;

        text-align: center;
        line-height: 48px;
        text-decoration: none;
        color: #f493a8;

        @include transition(all .3s ease);

        &:hover {
            background-color: #f493a8;
            color: #fff;
        }
    }
    .fancybox-prev, .fancybox-next {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #fff;

        top: 50%;
        right: 50px;
        left: auto;

        span {
            top: 50%;
            left: 50%;

            width: 11px;
            height: 15px;
            margin-top: -7px;
            margin-left: -3px;
            background-position: 0 0;
            visibility: visible;
        }

        &.icon-animated {
            @include transform(none);
        }
    }
    .fancybox-prev {
        margin-top: 55px;

        span {
            margin-left: -7px;
            @include retina-image('../img/gallery-prev', 11px 15px);
        }
    }
    .fancybox-next {
        span {
            @include retina-image('../img/gallery-next', 11px 15px);
        }
    }

    .fancybox-close, .fancybox-prev, .fancybox-next {
        opacity: 0;
    }

}
.bind-video-fancybox {
    .fancybox-close {
        opacity: 1;
        position: absolute;
        top: -21px;
        right: -21px;
    }    
}
.fancybox-transitionned-in {
    overflow: hidden;

    .fancybox-close, .fancybox-prev, .fancybox-next {
        opacity: 1;
    }
}
.fancybox-transition-in {
    overflow: hidden;
    
    @include keyframes(slideInFromRight) {
        from {
            @include transform(translate3d(200px, 0, 0));
            opacity: 0;
        }
        to {
            @include transform(translate3d(0, 0, 0));
            opacity: 1;
        }
    }

    .fancybox-close, .fancybox-prev, .fancybox-next {
        @include animation(slideInFromRight .3s ease-out);
        @include animation-fill-mode(forwards);
    }
    
    .fancybox-close { @include animation-delay(.1s);}
    .fancybox-next { @include animation-delay(.2s); }
    .fancybox-prev { @include animation-delay(.3s); }
}
