@include keyframes(rotateInClockwise) {
	0% {
		@include transform(rotate3d(0, 0, 1, 0));
	}
	40% {
		@include transform(rotate3d(0, 0, 1, -45deg));
	}
	100% {
		@include transform(rotate3d(0, 0, 1, 0));
	}
}

@include keyframes(rotateInNonClockwise) {
	0% {
		@include transform(rotate3d(0, 0, 1, 0));
	}
	40% {
		@include transform(rotate3d(0, 0, 1, 45deg));
	}
	100% {
		@include transform(rotate3d(0, 0, 1, 0));
	}
}

.icon-animated {
	position: relative;
	background-color: #fff;
	
	width: 84px;
	height: 84px;
	@include border-top-radius(42px);
	@include border-bottom-radius(42px);

	@media only screen and (max-width: $break-small) {
		@include transform(scale(0.5));
	}
	
	.inner  {
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 50%;

		width: 22px;
		height: 30px;
		margin-top: -15px;
		

		.top, .bottom {
			position: absolute;
			left: 0;
			width: 100%;
			height: 15px;
			overflow: hidden;
			
			&:before, &:after {
				content: '';
				display: block;
				border-style: solid;
				border-color: transparent;
				width: 0;
				height: 0;

				position: absolute;
				top: 0;
			}


		}

		.top {
			top: 0;
		}
		.bottom {
			top: 15px;
		}
	}

	&.icon-animated-right {
		.inner {
			margin-left: -7px;

			.top:after {
				border-width: 15px 0 0 22px;
				border-color: transparent transparent transparent #76c59c;
				@include transform-origin(100% 100%);
			}

			.bottom:after {
				border-width: 15px 22px 0 0;
				border-color: #178fb8 transparent transparent transparent;
				@include transform-origin(100% 0);
			}

			.top:before {
				border-width: 15px 0 0 22px;
				border-color: transparent transparent transparent #067d4d;
			}
			.bottom:before {
				border-width: 15px 22px 0 0;
				border-color: #f27d96 transparent transparent transparent;
			}

		}
	}

	&.icon-animated-left {
		.inner {
			margin-left: -15px;

			.top:after {
				border-width: 0 0 15px 22px;
				border-color: transparent transparent #76c59c transparent;
				@include transform-origin(0 100%);	
			}
			.bottom:after {
				border-width: 0 22px 15px 0;
				border-color: transparent #178fb8 transparent transparent;
				@include transform-origin(0 0);		
			}

			.top:before {
				border-width: 0 0 15px 22px;
				border-color: transparent transparent #067d4d transparent;
			}
			.bottom:before {
				border-width: 0 22px 15px 0;
				border-color: transparent #f27d96 transparent transparent;
			}
		}
	}

	/*
	 * Other sizes
	 */

	&.icon-animated-small {
		width: 45px;
		height: 45px;
		@include border-top-radius(23px);
		@include border-bottom-radius(23px);

		.inner {
			width: 12px;
			height: 16px;
			margin-top: -8px;

			.top, .bottom {
				height: 8px;
			}
			.bottom {
				top: 8px;
			}

		}
		
		&.icon-animated-right {
			.inner {
				margin-left: -4px;
				
				.top:after, .top:before {
					border-width: 8px 0 0 12px;
				}
				.bottom:after, .bottom:before {
					border-width: 8px 12px 0 0;
				}
			}
		}
		&.icon-animated-left {
			.inner {
				margin-left: -7px;

				.top:after, .top:before {
					border-width: 0 0 8px 12px;
				}
				.bottom:after, .bottom:before {
					border-width: 0 12px 8px 0;
				}
			}
		}
	}
	
	&:hover {

		&.icon-animated-right {
			.top:after {
				@include animation(rotateInClockwise .5s ease-out);
			}
			.bottom:after {
				@include animation(rotateInNonClockwise .45s ease-out .1s);
			}
		}
		&.icon-animated-left {
			.top:after {
				@include animation(rotateInNonClockwise .5s ease-out);
			}
			.bottom:after {
				@include animation(rotateInClockwise .45s ease-out .1s);
			}
		}
	}

}

a.icon-animated {
	display: block;
}
.bind-fancybox .icon-animated {
	position: absolute;
	.inner .top { top: 1px; } // weird gap...
}




@include keyframes(backgroundSlide) {
	0% {
		@include transform(translate3d(0,0,0) rotate3d(0,0,1,45deg));
	}
	99% {
		border-radius: 0;
	}
	100% {
		@include transform(translate3d(100%,0,0) rotate3d(0,0,1,45deg));
		border-radius: 50%;
	}
}

.icon-bubble {
	position: relative;
	overflow: hidden;
	@include transform(translateZ(0));
	
	&:before {
		position: relative;
		z-index: 10;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 5;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
	}
	&:hover:after {
		@include animation(backgroundSlide .4s ease);
		@include animation-fill-mode(forwards);
	}

	&.icon-instagram {
		background-color: #f69c20;
	}
	
	&.icon-facebook {
		background-color: #178fb8;
	}

	&.icon-vimeo {
		background-color: #f04e23;
	}
	
	&.bindicon-search {
	    background-color: #f27d96;
	}
	
	@include breakpoint(small){
		& {
			background-color: transparent;
		}
		&.icon-instagram {
			&:after {
				background-color: #f69c20;
			}
		}
		&.icon-facebook {
			&:after {
				background-color: #178fb8;
			}
		}

		&.icon-vimeo {
			&:after {
				background-color: #f04e23;
			}
		}
		
		&.bindicon-search {
		   &:after {
		        background-color: #f27d96;
		   }
		}
		&.iconlang-language {
		    &:after {
		        background-color: #ce313c;
		    }
		}
	}
}