.homeHolder
{
	opacity:0;
}
body:not(.jsactive) {
	
	.homeHolder{
		opacity:0;

		-webkit-animation-fill-mode:forwards;
		 -moz-animation-fill-mode:forwards;
		-ms-animation-fill-mode:forwards;
		 -o-animation-fill-mode:forwards;
			animation-fill-mode:forwards;

		-moz-animation-name: fadeContent;
		-moz-animation-iteration-count: 1;
		-moz-animation-timing-function: ease-in;
		-moz-animation-duration: 0.5s;
		-moz-animation-delay: 5s;

		-webkit-animation-name: fadeContent;
		-webkit-animation-iteration-count: 1;
		-webkit-animation-timing-function: ease-in;
		-webkit-animation-duration: 0.5s;
		-webkit-animation-delay: 5s;

		animation-name: fadeContent;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		animation-duration: 0.3s;
		animation-delay: 5s;
	}
}

@-moz-keyframes fadeContent {
	0% {
	 opacity:0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeContent {
 0% {
	 opacity:0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeContent {
 0% {
	 opacity:0;
	}
	100% {
		opacity: 1;
	}
}