#projectsCanvas {
	position:relative;
	top:0px;
	left:0px;
	display: block;
	background-color: #181818;
}

#projectsHolder, #gridHolder {
	position:absolute;
	z-index:2;
	width:100%;
	height:100%;
	display:none;
}

#projectsText {
	position: absolute;
	width: 80%;
	left:10%;
	height: 300px;
	color:#FFFFFF;
	@include font_neutratext();
	font-size:17px;
	top:80px;
	z-index:3;
	letter-spacing: 2px;
	line-height: 24px;

	 @include breakpoint(small) {
	 	position: absolute;
		width: 50%;
		left:25%;
		height: 300px;
		color:#FFFFFF;
		@include font_neutratext();
		font-size:27px;
		top: 210px;
		letter-spacing: 2px;
		line-height: 35px;
	 }

	 @include breakpoint(medium)
	 {
	 	top: 240px;
	 }
}

#projectsFilterMobile {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 65px;
	background: #000;

	@include breakpoint(small) {
		display: none;
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		min-width: 100%;
		height: 65px;
		width: 100%;

		border: none;

		font-size:16px;
		font-weight: bold;
		background:none;
		border: 0;
		color: #444;
		outline: none;
		opacity: 0;

		line-height:1.2;
	}

	#projectsFilterCurrent {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;

		width: 100%;
		height: 100%;
		padding-top: 22px;

		border-top: 6px #f27d96 solid;
		color: #f27d96;
		@include transition(border-color .5s $ease-out-quad);
		text-align: center;


		span {
			display: block;

			margin: 0 auto;
			width: 80%;

			@include font_proximanova(semibold);
			font-size: 11px;
			letter-spacing: 2px;
			text-transform: uppercase;
			text-align: left;

			@include retina-image('../img/select-arrow-up', 20px 12px);
			background-repeat: no-repeat;
			background-position: right 0;
		}
	}
}
#projectsFilter {
	display: none;
	@include transition(top 0.5s ease);

	@include breakpoint(small)
	{
		position: fixed;
		display: block;
		top:88px;
		width:100%;

		background-color: #000000;
	}

	@include breakpoint(large)
	{
		width:80%;
		left: 10%;
		right: 10%;
	}

	&.hidden 
	{
		top: -75px;
	}

	.filter-item {
		/*padding-top:20px;
		padding-left:20px;
		padding-right: 20px;*/
		display:inline-block;
		
		width: (100%/6);
		color:#FFFFFF;
		border-top:5px solid #FFFFFF;
		cursor: pointer;
		height: 100%;
		@include transition(opacity .5s $ease-out-quad);

		&.inactive {
			opacity: 0.5;
		}
		.table {
			display: table;
			height:65px;
			width:100%;
			padding-left:10%;
			padding-right:10%;
		}
		span {
			font-size:11px;
			letter-spacing: 1.5px;
			text-transform: uppercase;
			@include font_proximanova(semibold);
			display: table-cell;
			vertical-align: middle;
			height:100%;
			text-align: center;

		}
	}
}

body:not(.jsactive) {
  
  .projectsHolder, .gridHolder{
    opacity:0;

    -webkit-animation-fill-mode:forwards;
     -moz-animation-fill-mode:forwards;
    -ms-animation-fill-mode:forwards;
     -o-animation-fill-mode:forwards;
        animation-fill-mode:forwards;

    -moz-animation-name: fadeContent;
      -moz-animation-iteration-count: 1;
      -moz-animation-timing-function: ease-in;
      -moz-animation-duration: 0.5s;
      -moz-animation-delay: 5s;

      -webkit-animation-name: fadeContent;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 5s;

      animation-name: fadeContent;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
      animation-delay: 5s;
  }
}
@-moz-keyframes fadeContent {
      0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @-webkit-keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
