#logoAnimation {
	width: 1000px;
	//height: 182px;
	display: none;
	//@include breakpoint(small)
	//{
		height:363px;
	//}

	position: absolute;
	top:50%;
	background-repeat: no-repeat;
	background-size:100%;
    //overflow: hidden;
	left:50%;
	
	z-index:2;
	background-image: url("../img/logosequence_new.png");

	transform-origin:0 0;
	-webkit-transform-origin:0 0;
	-moz-transform-origin:0 0;
	
    $duration : 2s;

    $steps : 40;

    -webkit-animation: logoAnimation $duration steps($steps) 1;
	   -moz-animation: logoAnimation $duration steps($steps) 1;
    	-ms-animation: logoAnimation $duration steps($steps) 1;
         -o-animation: logoAnimation $duration steps($steps) 1;
	        animation: logoAnimation $duration steps($steps) 1;

	-webkit-animation-play-state: paused;
	   -moz-animation-play-state: paused;
		-ms-animation-play-state: paused;
		 -o-animation-play-state: paused;
			animation-play-state: paused;

	-webkit-animation-fill-mode:forwards;
	   -moz-animation-fill-mode:forwards;
		-ms-animation-fill-mode:forwards;
		 -o-animation-fill-mode:forwards;
		    animation-fill-mode:forwards;

	&.animationRunning {
		-webkit-animation-play-state: running;
	       -moz-animation-play-state: running;
			-ms-animation-play-state: running;
			 -o-animation-play-state: running;
				animation-play-state: running;

	}
}

body.jsactive {
	#logoAnimation{
		display: block;
	}
}
$pixelRatioList: 1;// 1.3 1.5 2 3;

@each $currentRatio in $pixelRatioList
{
	@media only screen and (-webkit-min-device-pixel-ratio: $currentRatio)
	{  
		#logoAnimation {
			width:#{1000/$currentRatio}px;
			height:#{363/$currentRatio}px;
		}

		@-webkit-keyframes logoAnimation {
		   from { background-position: 0 0px; }
		     to { 
		     	background-position: 0 -#{14520/$currentRatio}px;
		     }
		}
	}
}
/*@-webkit-keyframes logoAnimation {
   from { background-position: 0 0px; }
     to { 
     	background-position: 0 -7260px;
     }
}

@include breakpoint(small)
{
	@-webkit-keyframes logoAnimation {
	   from { background-position: 0 0px; }
	     to { background-position: 0 -14520px; }
	}
}*/

@-moz-keyframes logoAnimation {
   from { background-position: 0 0px; }
     to { background-position: 0 /*-15136px;*/-14520px; }
}

@-ms-keyframes logoAnimation {
   from { background-position: 0 0px; }
     to { background-position: 0 /*-15136px;*/-14520px; }
}

@-o-keyframes logoAnimation {
   from { background-position: 0 0px; }
     to { background-position: 0 /*-15136px;*/-14520px; }
}

/*@keyframes logoAnimation {
   from { background-position: 0 0px; }
     to { background-position: 0 -14520px; }
}*/