#container3D {
	position:fixed;
	top:0px;
	left:0px;
	width:100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	//background-color: transparent;
	background-color: #171717;
	display: none;
}
body.jsactive {
	#container3D{
		display: block;
	}
}