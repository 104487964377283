
#menu_holder {
    position: fixed;
    margin: 0;
    width: 100%;
    height: 60px;
    top: 0px;
    z-index: 10;
    background-color: #000000;
    @include transition(height 0.2s ease);
    @include transition(top 0.5s ease);
    display: none;

    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        background-image: url("../img/bind_logo_menu.png");
        background-repeat: no-repeat;
        background-position: 25px 15px;
        background-size: auto 29px;
        width: 160px;
        height: 60px;
        z-index: 10;
        cursor: pointer;
    }

    .hamburger {
        position: absolute;
        right: 14px;
        bottom: 14px;
        background-image: url("../img/hamburger.png");
        background-repeat: no-repeat;
        width: 36px;
        height: 31px;
        background-size: 18px auto;
        background-position: center center;

    }

    .icon {
        position: absolute;
        bottom: 256px;
        background-color: #F2f2f2;
        color: #bababa;
        padding-top: 8px;
        text-align: center;
        border-radius: 28px;
        width: 28px;
        height: 28px;
        display: block;
        cursor: pointer;
        font-size: 14px;

        text-decoration: none;
    }

    #searchHolder {
        display: block;
        position: relative;
        max-height: 0;
        padding-top: 0px;
        overflow: hidden;
        background-color: #FFFFFF;
        @include transition(max-height 0.2s 0.2s ease, padding-top 0.2s 0.2s ease);


        .searchPositioner {
            display: block;
            position: relative;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding-top: 20px;
            opacity: 0;
            @include transition(opacity 0.2s);
            .searchInputHolder {
                display: block;
                position: relative;
                margin-left: 10%;
                width: 80%;
                background-color: #f2f2f2;
                height: 25px;
                @include border-left-radius(25px);
                @include border-right-radius(25px);
                padding-left: 25px;
                padding-right: 25px;

                input {
                    position: relative;
                    @include font_neutratext();
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }

            #searchResultHolder {
                margin-left: 10%;
                width: 80%;
                position: relative;
                display: block;
                margin-top: 20px;

                a {
                    text-decoration: none;
                    color: #bababa;
                    font-size: 15px;
                    display: block;
                    position: relative;
                    margin-bottom: 20px;

                    &:hover {
                        color: #f27d96;
                    }
                }
            }
        }

        &.active {
            padding-top: 63px;
            overflow: auto;
            max-height: 600px;
            @include transition(max-height 0.2s ease, padding-top 0.2s ease);

            .searchPositioner {
                @include transition(opacity 0.2s 0.2s);
                opacity: 1;
            }
            @include breakpointMax(small) {
                & ~ div {
                    &.main-navigation {
                        @include transition(height 0.2s ease);
                        height: 177px;
                        ul {
                            @include transition(margin-top 0.2s);
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
    }

    #languageHolder {
        display: block;
        position: relative;
        max-height: 0;
        padding-top: 0px;
        overflow: hidden;
        background-color: #FFFFFF;
        @include transition(max-height 0.2s 0.2s ease, padding-top 0.2s 0.2s ease);

        .languagePositioner {
            display: block;
            position: relative;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding-top: 20px;
            opacity: 0;
            @include transition(opacity 0.2s);

            #languageResultHolder {
                margin-left: 30%;
                width: 60%;
                position: relative;
                display: block;
                margin-top: 30px;
                margin-bottom: -20px;

                .lang-selector {
                    text-decoration: none;
                    color: #bababa;
                    font-size: 15px;
                    display: block;
                    position: relative;
                    margin-bottom: 20px;
                    padding-left: 50px;

                    &:hover {
                        color: #f27d96;
                    }

                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: 18px;

                    &.lang-nl {
                        background-image: url('../img/lang/nl.png');

                    }

                    &.lang-en {
                        background-image: url('../img/lang/en.png');

                    }
                }
            }
        }

        &.active {
            padding-top: 63px;
            overflow: auto;
            max-height: 600px;
            @include transition(max-height 0.2s ease, padding-top 0.2s ease);

            .languagePositioner {
                @include transition(opacity 0.2s 0.2s);
                opacity: 1;
            }
        }
    }

    #languageIcon {
        right: 167px;
    }

    #instagramIcon {
        right: 91px;
    }

    #facebookIcon {
        //right : 91px;
        right: 53px;
    }

    #twitterIcon {
        right: 15px;
    }

    #searchIcon {
        right: 129px;
        z-index: 12;

        &.active {
            background-color: #f27d96;
            color: #FFFFFF;
        }
    }

    #vimeoIcon {
        right: 15px;
    }

    &.hidden {
        top: 0px;
    }

    &.expanded {
        height: 300px;

        .main-navigation {
            @include transition(height 0.2s 0.2s ease);
            display: block;
            height: 240px;

        }
    }

    .menu {
        @include clearfix;
    }

    .main-navigation {
        display: block;
        position: relative;
        width: inherit;
        height: 0;
        overflow: hidden;
        background-color: #FFFFFF;
        @include transition(height 0.2s ease);

        ul {
            margin-top: 63px;
            list-style: none;
            padding: 0;
            @include transition(margin-top 0.2s 0.2s);

            li {
                position: relative;
                overflow: hidden;
                display: inline-block;
                width: 50%;
                padding-top: 32px;
                text-align: center;
                float: left;
                height: 86px;
                a {
                    display: inline-block;
                    @include font_neutratext();
                    font-size: 17px;
                    letter-spacing: 1px;
                    color: #808080;
                    text-decoration: none;
                    // text-transform: capitalize;

                    @include transition(color 0.2s ease);

                    &:hover {
                        color: #000000;
                    }

                    &:active, &:focus {
                        outline: none;
                    }

                }

                border-top: 1px solid #e9e9e9;

                &:nth-child(odd) {
                    border-right: 1px solid #e9e9e9;
                }

                &.current_page_item, &.current-page-ancestor, &.current_page_parent {
                    a {
                        color: #000;

                        &:before {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(small) {

        margin: 0px;
        width: 100%;
        height: 88px;

        .icon {
            bottom: 30px;
            background-color: #272727;
            color: #939393;
            display: block;
        }

        .icon-facebook,
        .icon-twitter,
        .icon-instagram,
        .icon-vimeo {
            display: none;
        }

        #searchIcon {
            right: 35px;
        }

        #languageIcon {
            right: 78px;
        }


        .logo {
            background-position: 40px 23px;
            background-size: auto 35px;
            left: 10%;
        }

        &.hidden {
            top: -88px;
        }

        .hamburger {
            display: none;
        }

        #searchHolder {
            display: block;
            position: absolute;
            max-height: 600px;
            right: 0px;
            padding-top: 20px;
            top:88px;
            overflow: auto;
            background-color: inherit;
            z-index: 0;
            //max-width: 0px;
            width: 300px;
            visibility: hidden;
           // @include transition(max-width 0.2s 0.2s ease, opacity 0.2s);
            @include transform(translate(0,-100%));
            @include transition(transform  .7s $ease-out-expo, visibility .7s $ease-out-expo);

            opacity: 1;

            .searchPositioner {
                display: block;
                position: relative;
                width: 100%;
                border: none;
                padding-top: 0px;
                opacity: 1;

                .searchInputHolder {
                    display: block;
                    position: relative;
                    margin-left: 20px;
                    width: calc(100% - 40px);
                    background-color: #1e1e1f;
                    height: 25px;
                    @include border-left-radius(25px);
                    @include border-right-radius(25px);
                    padding-left: 25px;
                    padding-right: 25px;

                    input {
                        position: relative;
                        top: 2px;
                        width: 100%;
                        border: none;
                        outline: none;
                        color: #f3f3f3;
                        background-color: transparent;
                    }
                }

                #searchResultHolder {
                    padding-left: 25px;
                    padding-right: 25px;
                    margin: 0;
                    width: 100%;
                    position: relative;
                    display: block;
                    margin-top: 20px;
                    background-color: #121212;
                    a {
                        text-decoration: none;
                        color: #bababa;
                        font-size: 15px;
                        display: block;
                        position: relative;
                        padding-top: 20px;
                        margin: 0;

                        &:last-of-type {
                            padding-bottom: 20px;
                        }
                        &:hover {
                            color: #f27d96;
                        }
                    }
                }
            }

            &.active {
                padding-top: 20px;
                overflow: auto;
                max-height: 600px;
                max-width: 300px;
                opacity: 1;

               // @include transition(max-width 0.2s ease, opacity 0.1s 0.2s);

                //.searchPositioner {
                //    @include transition(opacity 0.2s 0.2s);
                //    opacity: 1;
                //}
                @include transform(translateY(0));
                visibility: visible;

                @include transition(transform .7s $ease-out-expo);
            }
        }

        #languageHolder {
            display: block;
            position: absolute;
            right: 0px;
            padding-top: 0px;
            overflow: auto;
            background-color: inherit;
            z-index: 0;
            width: 200px;
            top: 88px;
            visibility: hidden;
            max-height: 600px;
            //@include transition(max-height 0.2s 0.2s ease, opacity 0.2s);
            //opacity: 1;
            @include transform(translate(0,-100%));
            @include transition(transform  .7s $ease-out-expo, visibility .7s $ease-out-expo);


            .languagePositioner {
                display: block;
                position: relative;
                width: 100%;
                border: none;
                padding-top: 0px;
                opacity: 1;

                #languageResultHolder {
                    padding-left: 25px;
                    padding-right: 25px;
                    margin: 0;
                    width: 100%;
                    position: relative;
                    display: block;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-color: #121212;

                    .lang-selector {
                        text-decoration: none;
                        color: #bababa;
                        font-size: 15px;
                        display: block;
                        position: relative;
                        padding-top: 10px;
                        margin: 0;

                        padding-bottom: 10px;

                        &:hover {
                            color: #f27d96;
                        }
                    }
                }
            }

            &.active {
                padding-top: 0px;
                //overflow: auto;
                //opacity: 1;
                //@include transition(max-height 0.2s ease, opacity 0.1s 0.2s);

                //.languagePositioner {
                    //@include transition(opacity 0.2s 0.2s);
                    //opacity: 1;
                //}
                @include transform(translateY(0));
                visibility: visible;

                @include transition(transform .7s $ease-out-expo);
            }
        }
        .main-navigation {

            display: block;
            position: absolute;
            background-color: #000000;
            height: inherit;
            padding-left: 10%;
            width:100%;
            @include transition(height 0s ease);

            ul {
                margin: 20px 0 0 0;
                padding: 0;
                list-style: none;
                margin-left: 180px;

                li {
                    border: 0;
                    width: auto;
                    float: none;
                    padding: 0;
                    border: none;
                    position: relative;
                    overflow: hidden;
                    display: inline-block;

                    &:nth-child(odd) {
                        border-right: none;
                    }
                    a {
                        padding-right: 20px;
                        font-size: 17px;
                        padding-top: 10px;
                        display: inline-block;
                        @include font_neutratext();
                        font-size: 19px;
                        letter-spacing: 1px;
                        color: #808080;
                        text-decoration: none;
                        // text-transform: capitalize;

                        @include transition(color 0.2s ease);

                        &:hover {
                            color: #FFFFFF;
                        }

                    }

                    &.current_page_item, &.current-page-ancestor, &.current_page_parent {
                        a {
                            color: #fff;

                            &:before {
                                display: block;
                            }
                        }
                    }
                }
            }

        }

        .main-navigation {
            padding-left: 0px;
            ul {

                li {
                    a {
                        padding-right: 30px;
                    }

                }
            }
        }

        .logo {
            left: 0px;
        }
    }

    @include breakpoint(medium) {

        .icon-facebook,
        .icon-twitter,
        .icon-instagram,
        .icon-vimeo {
            display: block;
        }

        #languageIcon {
            &:before{
                font-family: 'Neutratext', sans-serif !important;
            }
            right: 207px;
            outline: none;
            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                color: #FFFFFF;
            }
        }

        #instagramIcon {
            right: 164px;
            outline: none;
            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                color: #FFFFFF;
            }
        }

        #searchIcon {
            right: 35px;
            outline: none;

            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                color: #FFFFFF;
            }
        }

        #facebookIcon {
            right: 121px;

            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                color: #FFFFFF;
            }
        }
        #twitterIcon {
            right: 78px;

            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                background-color: #f27d96;
                color: #FFFFFF;
            }
        }
        #vimeoIcon {
            right: 78px;

            @include transition(background-color 0.2s ease, color 0.2s ease);

            &:hover {
                color: #FFFFFF;
            }
        }

        .main-navigation {
            padding-left: 0px;
            ul {
                margin-left: 240px;
                li {
                    a {
                        padding-right: 40px;
                    }

                }
            }
        }

        .logo {
            left: 0px;
        }

        #searchHolder {
            display: block;
        }
    }

    @include breakpoint(large) {

        width: 80%;
        margin: 0%;
        margin: 0px 10%;

        .logo {
            left: 0px;
        }
        .main-navigation {
            padding-left: 0px;
            ul {

                li {
                    a {
                        padding-right: 60px;
                        font-size: 19px;
                    }

                }
            }
        }
    }
}

body:not(.jsactive) {

    #menu_holder {
        display: block;
        top: -88px;

        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -ms-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -moz-animation-name: dropHeader;
        -moz-animation-iteration-count: 1;
        -moz-animation-timing-function: ease-in;
        -moz-animation-duration: 0.5s;
        -moz-animation-delay: 5s;

        -webkit-animation-name: dropHeader;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-timing-function: ease-in;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-delay: 5s;

        animation-name: dropHeader;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.3s;
        animation-delay: 5s;
    }
}

@-moz-keyframes dropHeader {
    0% {
        top: -88px;
    }
    100% {
        top: 0px;
    }
}

@-webkit-keyframes dropHeader {
    0% {
        top: -88px;
    }
    100% {
        top: 0px;
    }
}

@keyframes dropHeader {
    0% {
        top: -88px;
    }
    100% {
        top: 0px;
    }

}