.page-template-template-project-php {
	#gridHolder {
		display: block !important; //override js setting for this page
		object svg {
			width: 100%;
		}
	}
	#content_json {
		z-index: 3; //over the grid
	}
}
#project {
	position:relative;
	width:100%;
	@include font_neutratext();
	background-color: transparent;
	font-size: 17px;

	
	#header_image {
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		width:100%;
		height:300px;

		@include breakpoint(small)
		{
			padding-top: 55%;
			height: auto;
		}
		@include breakpoint(medium)
		{
			padding-top: 45%;
			height: auto;
		}

		h1 {
			display:block;
			position: absolute;
			left:20px;
			right: 20px;
			bottom: 20px;

			color:#FFFFFF;
			font-size: 38px;

			@include breakpoint(small)
			{
				font-size: 54px;
				left: 5%;
				bottom:50px;
			}
			@include breakpoint(large)
			{
				left: 10%;
			}
			@include breakpoint(xlarge)
			{
				left: 20%;
			}
		}

		h2 {
			display:block;
			position: absolute;
			left:20px;
			bottom:12px;

			color:#FFFFFF;
			letter-spacing: 0.06em;
			font-size: 15px;

			@include breakpoint(small)
			{
				left:5%;
				bottom: 45px;
				font-size: 17px;
			}
			@include breakpoint(large)
			{
				left: 10%;
			}
			@include breakpoint(xlarge)
			{
				left: 20%;
			}
		}
		.gradient {
			width:100%;
			height:208px;
			position:absolute;
			bottom:0px;
			background-image: url('../img/gradient.png');
		}
	}

	.large_column {
		width: 100%;

		@include breakpoint(small)
		{
			width: 57%;
			padding-right: 60px;
		}
	}
	.small_column {
		width: 100%;

		@include breakpoint(small)
		{
			padding-right: 60px;
			width: 43%;
		}
	}

	.one_half {
		padding-right: 60px;
		width: 100%;

		@include breakpoint(small)
		{
			width: 50%;
		}
	}

	.last_column {
		padding-right: 0px;
	}
	.hidden {
		visibility:hidden;
		position: absolute;
		top:0;
	}
	.post-content {
		position: relative;
		background-color: #FFFFFF;
		padding-left: 5%;
		padding-right: 5%;
		padding-top: 15px;
		padding-bottom:100px;
		line-height: 25px;

		.grey {
			font-size: 15px;
			color: #b4b4b4;
		}

		.small_column {
			font-size: 15px;
			
			.grey {
				color: #808080;
			}
		}

		@include breakpoint(small) {
			padding-top: 50px;
		}
	}

	.video_image {
		position: relative;
		padding-left: 5%;
		padding-right: 5%;
		margin-top: -75px;
		/*img {
			width:100%;
			height:auto;
		}*/

		&.more-videos {
			margin-top: 0;
		}

		& > div {
			position: relative;

			&:hover {
				figure {
					@include transform(scale(1.05));
				}
			}
		}
		a {
			text-decoration: none;
			display: block;
			position: relative;
			overflow: hidden;
		}
		h2 {
			text-align: center;
			color: #818181;
			font-size: 17px;
			margin: 30px 0;
			min-height: 1px;
		}
		.preserve-ratio {
			padding-top: 56.25%;
		}
		figure {
			overflow: hidden;
			position: absolute;
			top: 0;
			bottom: 0;
			width:100%;
			background-size:cover;
			text-decoration: none;
		}

		.play_button {
			position: absolute;
			display: block;
			text-align: center;
			margin-top:-42px;
			margin-left:-42px;
			
			top: 100%;
			left: 50%;

			img {
				width: 42px;
				height: 42px;
			}

			@include breakpoint(small)
			{	
				img {
					width:84px;
					height: 84px;
				}
			}
		}

		a {
			overflow: hidden;
		}
		figure {
			@include transition(transform .8s ease-in-out);
		}

		@include breakpoint(small) {
			h2 {
				margin: 70px 0;
			}
		}
	}

	.project-awards {
		position: relative;
		overflow: hidden;

		figure {
			@include retina-image('../img/awards_bg', 373px 270px);
			background-position: center 40%;
			background-repeat: no-repeat;

			position: absolute;
			top: 50%;
			left: 50%;
		
			width: 373px;
			height: 270px;
			margin-top: -130px;
			margin-left: -186px;
		}

		ul {
			margin: 50px 0 70px;
			padding: 0 5%;
			list-style-type: none;

			li {
				color: #fff;
				text-align: center;
				// line-height: 70px;
				padding: 12px;
			}
		}

		@include breakpoint(small) {
			ul {
				margin: 115px 0 115px;
			}
		}
	}

	.project-slideshow {
		position: relative;
		padding-left: 5%;
		padding-right: 5%;
		margin-bottom: 60px;

		-webkit-touch-callout: none;
	    -webkit-user-select: none;
	    -khtml-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;

		ul {
			position: relative;
			padding-top: 100%;
			margin: 0;
			list-style-type: none;

			li {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;

				@include keyframes(slideInPrev) {
					from {
						@include transform(scale(1.05));
						opacity: 0;
					}
					to {
						@include transform(scale(1));
						opacity: 1;
					}
				}
				@include keyframes(slideOutPrev) {
					from {
						@include transform(scale(1));
						opacity: 1;
					}
					to {
						@include transform(scale(0.95));
						opacity: 0;
					}
				}
				@include keyframes(slideInNext) {
					from {
						@include transform(scale(.95));
						opacity: 0;
					}
					to {
						@include transform(scale(1));
						opacity: 1;
					}
				}
				@include keyframes(slideOutNext) {
					from {
						@include transform(scale(1));
						opacity: 1;
					}
					to {
						@include transform(scale(1.05));
						opacity: 0;
					}
				}

				@include animation(slideOutNext .3s ease-in-out);
				// @include animation-fill-mode(forwards);
				&.active {
					opacity: 1;
					@include animation(slideInNext .4s ease-in-out);
					// @include animation-fill-mode(forwards);
				}

				figure {
					width: 100%;
					padding-top: 100%;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
				}

				p {
					display: block;
					width: 100%;
					height: auto;
					position: absolute;
					text-align: center;
					color: #808080;

					padding: 0 20%;
					line-height: 1.5em;
					margin: 30px 0;
				}
				&.slide-portrait {
					p {
						top: 105%;
						bottom: auto;
					}
				}
			}

			&.direction-prev {
				li {
					// opacity: 0;
					@include animation(slideOutPrev .3s ease-in-out);
					// @include animation-fill-mode(forwards);
					&.active {
						// opacity: 1;
						@include animation(slideInPrev .4s ease-in-out);
						// @include animation-fill-mode(forwards);
					}
				}
			}

		}
		.controls {
			position: absolute;
			top: 50%;
			height: 0;
			width: 90%;
			margin-top: -42px;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			.control-prev, .control-next {
				position: absolute;
				width: 84px;
				height: 84px;
				background: #fff;

				@include border-top-radius(42px);
				@include border-bottom-radius(42px);

				cursor: pointer;
				-webkit-tap-highlight-color: rgba(0,0,0,0);

				.inner {
					@include transform(scale(2));
				}
			}
			.control-prev {
				left: -32px;
			}
			.control-next {
				right: -32px;
			}

			@include breakpoint(small) {
				margin-top: -23px;

				.control-prev, .control-next {
					width: 45px;
					height: 45px;

					@include border-top-radius(22.5px);
					@include border-bottom-radius(22.5px);

					.inner {
						@include transform(scale(1));
					}
				}
				.control-prev {
					left: -22px;
				}
				.control-next {
					right: -22px;
				}
			}
		}
	}

	@include breakpoint(small)
	{
		.project-item {
			opacity: 0;
			margin-top:150px;
			
			&.in-view {
				margin-top: 50px;
				opacity: 1;
			}
			&.animate {
			  @include transition(margin-top 0.5s ease-out, opacity 0.5s ease-in-out);
			}
		}

		.post-content {
			padding-bottom:200px;
		}
	}
	.project-quote {
		color:#FFFFFF;
		font-size: 28px;
		padding-left:5%;
		padding-top:40px;
		max-width: 100%;
		display: inline-block;
		position: relative;

		@include breakpoint(small)
		{
			max-width: 50%;
			padding-top:50px;

		}
	}

	.clear {
		clear:both;
	}

	.project-image2 {
		position: relative;
		padding-left: 5%;
		padding-right: 5%;
		max-width: 100%;
		padding-top: 40px;

		margin-top:100px;
			
		&.in-view {
			margin-top: 0px;
			opacity: 1;
		}

		@include breakpoint(small)
		{
			max-width: 40%;
			padding-top: 75px;
			padding-right: 0;

		}
		img {
			width:100%;
			height:auto;
			display: block;
		}
		span {
			margin-top: 25px;
			display: block;
			width:100%;
			color:#808080;
			font-size: 17px;
		}
	}

	
	.project-image1 {
		position: relative;
		padding-left: 5%;
		padding-right: 5%;
		max-width: 100%;
		padding-top: 40px;
		float:none;
		
		@include breakpoint(small)
		{
			max-width: 35%;
			padding-top: 0px;
			padding-left:0;
			padding-right:0;
			float:right;
			right:10%;
			padding-bottom: 60px;
			margin-top:250px;

			 &.in-view {
				margin-top: 150px;
				opacity: 1;
			}
		}

		img {
			width:100%;
			height:auto;
			display: block;
		}
		span {
			margin-top: 25px;
			display: block;
			width:100%;
			color:#808080;
			font-size: 17px;
		}
	}

	.navigate-projects {
		position: relative;
		display: block;
		width:100%;
		// height:120px;
		
		clear: both;
		
		margin: 0;
		padding:0;
		margin-top: 40px;

		h4 {
			font-size: 24px;
			color: #fff;
			text-align: center;
			letter-spacing: 0.06em;
			margin: 2em 0 22px;
		}
		

		@include breakpoint(small)
		{
			height:300px;
			margin-top: 175px;
			h4 {
				margin-bottom: 44px;
			}
		}
		
		.project-navigation {
			cursor: pointer;
			background-color: #000000;

			.table {
				@include transition(opacity .5s $ease-out-quad);
			}
			&.inactive .table{
				opacity: .3;
			}
			.project-title {
				@include transition(color .5s $ease-out-quad);
			}

			&:hover {
				&.previous {
					.project-title {
						color: #76c59c;
					}
				}
				&.next {
					.project-title {
						color: #f69c20;
					}
				}
			}
		}

		.table {
			display: table;
			width:100%;
			height: inherit;

			.table-cell {
				display: table-cell;
				width:100%;
				height: inherit;
				text-align: center;
				vertical-align: middle;

				padding-bottom: 15px;

				a {
					text-decoration: none;
					color: #FFFFFF;
					font-size: 17px;

					span {
						display: none;
						font-size: 28px;
						line-height: 65px;
					}
				}

				@include breakpoint(small) {
					padding-bottom: 40px;

					a span {
						display: inline;
					}
				}
			}
		}
		.previous, .next {
			width:50%;
			height: 175px;
			display: inline-block;
			padding:0;
			margin: 0;

		}
		@include breakpoint(small) {
			.previous, .next {
				height: 320px;
			}
		}
		.previous {
			position: relative;
			border-top: 5px solid #76c59c;
			float: left;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				border-right: 1px solid #282828;
			}
		}

		.next {
			border-top: 5px solid #f69c20;
		}
	}



	@include breakpoint(large) {
		.post-content {
			padding-left: 10%;
			padding-right: 10%;
			padding-top: 65px;
			
		}

		.video_image {
			padding-left: 10%;
			padding-right: 10%;
			
		}

		.project-quote {
			color:#FFFFFF;
			font-size: 28px;
			padding-left:14%;
			padding-top:50px;
			max-width: 50%;
			display: inline-block;
			position: relative;
		}

		.project-slideshow {
			padding-left: 10%;
			padding-right: 10%;
			.controls {
				width: 80%;
			}
		}
	}

	@include breakpoint(xlarge) {
		.post-content {
			padding-left: 20%;
			padding-right: 20%;	
		}
		.video_image {
			padding-left: 20%;
			padding-right: 20%;
			
		}
		.project-slideshow {
			padding-left: 20%;
			padding-right: 20%;
			.controls {
				width: 60%;
			}
		}
	}

	.project-button {

	}

	.read-more-toggler {
		text-decoration: none;
		color: #178fb8;
		vertical-align: bottom; // why ?
	}

}