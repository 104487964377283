* {
	@include font_neutratext();
	box-sizing: border-box;
}
html, body {
	width:100%;
	height:100%;
	background-color: #171717;

}

a:focus, a:active, 
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline : 0;
}



#content_json {
	z-index:1;
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	display:none;
}
#content {
	color:#FFFFFF !important;

}
body:not(.jsactive)
{
	#project {
		color:#666666 !important;
	}
}
body.jsactive {
	#content {
		display: none;
	}	
	#content_json {
		display:block;
	}
}

.grey20p
{
	font-size: 16px;
	@include breakpoint(small) {
		font-size: 20px;
	}
	color:#808080;
}

.grey15p
{
	font-size: 15px;
	color:#808080;
}

.grey17p
{
	font-size: 17px;
	color:#808080;
}

.semiBold {
	font-weight: 600;
}

.grey {
	color:#808080;
}

.small_column, .large_column {
	float: left;
}