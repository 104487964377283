$break-small: 700px;
$break-medium: 1000px;
$break-large: 1200px;
$break-xlarge: 1450px;

@mixin breakpoint($size) {
    @if $size == small {
        @media only screen and (min-width: $break-small) {  @content ; }
    }
    @else if $size == medium {
        @media only screen and (min-width: $break-medium) {  @content ; }
    }
    @else if $size == large {
        @media only screen and (min-width: $break-large)  {  @content ; }
    }
    @else if $size == xlarge {
        @media only screen and (min-width: $break-xlarge) {  @content ; }
    }
}

@mixin breakpointMax($size) {
    @if $size == small {
        @media only screen and (max-width: $break-small) {  @content ; }
    }
    @else if $size == medium {
        @media only screen and (max-width: $break-medium) {  @content ; }
    }
    @else if $size == large {
        @media only screen and (max-width: $break-large)  {  @content ; }
    }
    @else if $size == xlarge {
        @media only screen and (max-width: $break-xlarge) {  @content ; }
    }
}
