@charset "UTF-8";

@font-face {
  font-family: "icons";
  src:url("fonts/icons/icons.eot");
  src:url("fonts/icons/icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/icons/icons.woff") format("woff"),
    url("fonts/icons/icons.ttf") format("truetype"),
    url("fonts/icons/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "a";
}
.icon-instagram:before {
  content: "b";
}
.icon-twitter:before {
  content: "c";
}
.icon-plus:before {
  content: "e";
}
.icon-dash:before {
  content: "f";
}
.icon-youtube:before {
  content: "d";
}
.icon-vimeo:before {
  content: "g";
}
.icon-x:before {
  content: "h";
}
.icon-playback-play:before {
  content: "i";
}

@charset "UTF-8";

@font-face {
  font-family: "bindicons";
  src:url("fonts/icons/bindicons.eot");
  src:url("fonts/icons/bindicons.eot?#iefix") format("embedded-opentype"),
    url("fonts/icons/bindicons.woff") format("woff"),
    url("fonts/icons/bindicons.ttf") format("truetype"),
    url("fonts/icons/bindicons.svg#bindicons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "bindicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="bindicon-"]:before,
[class*=" bindicon-"]:before {
  font-family: "bindicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bindicon-search-old:before {
  content: "a";
}

.bindicon-search:before {
    content: "b";
}

.iconlang-language {
    outline:none;
    
    &:before {
        text-transform: uppercase;
        font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 12px;
      top: -3px;
      left: 1px;
    }
    &.language-nl:before {
        content: "NL";
    }
    &.language-en:before {
        content: "EN";
    }  
}
