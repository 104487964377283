#overlayHolder {
   position:fixed;
   top:0px;
   left:0px;
   width: 100%;
   height: 100%;
   z-index: 100;
   display: none;
   overflow: hidden;
 }
#overlayCanvas {
  position: relative;
  top:0px;
  left:0px;
}