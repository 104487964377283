#aboutHolder {
	opacity: 1;

	@include breakpoint(small) {
		opacity: 0;

		&.active {
			@include transition(opacity 2s $ease-out-quad .5s);
			opacity: 1.0;
		}
	}
}

body:not(.jsactive) {
  
  #aboutHolder{
	opacity:0;

	-webkit-animation-fill-mode:forwards;
	 -moz-animation-fill-mode:forwards;
	-ms-animation-fill-mode:forwards;
	 -o-animation-fill-mode:forwards;
		animation-fill-mode:forwards;

	-moz-animation-name: fadeContent;
	  -moz-animation-iteration-count: 1;
	  -moz-animation-timing-function: ease-in;
	  -moz-animation-duration: 0.5s;
	  -moz-animation-delay: 5s;

	  -webkit-animation-name: fadeContent;
	  -webkit-animation-iteration-count: 1;
	  -webkit-animation-timing-function: ease-in;
	  -webkit-animation-duration: 0.5s;
	  -webkit-animation-delay: 5s;

	  animation-name: fadeContent;
	  animation-iteration-count: 1;
	  animation-timing-function: ease-in;
	  animation-duration: 0.3s;
	  animation-delay: 5s;
  }
}

@-moz-keyframes fadeContent {
  0% {
	 opacity:0;
  }
  100% {
	  opacity: 1;
  }
}
@-webkit-keyframes fadeContent {
 0% {
	 opacity:0;
  }
  100% {
	  opacity: 1;
  }
}
@keyframes fadeContent {
 0% {
	 opacity:0;
  }
  100% {
	  opacity: 1;
  }
}

.about {
	background-color: transparent;

	.content_section {
		width: 80%;
		position: absolute;

		margin-left: 10%;
	}

	.about_section {
		// margin-top: 100px;
		margin-bottom: 100px;

		@include breakpoint(small) {
			// margin-top: 15%;
		}
	}

	.bind_section {
		margin-bottom: 100px;

		// top: 50%;
		// left: 50%;
		// margin-left: 0px;

		color: #FFFFFF;
	}

	#partnerSVG {
		position: absolute;
	}

	#partnersSection {
		text-align: center;
		margin-top: 10%;
		margin-bottom: 0;

		.partners-table {
			position: relative;
			@include clearfix();

			padding-bottom: 100px;

			.partner-box {
				display: block;
				width: 50%;
				height: 135px;
				float: left;
				position: relative;

				&.no-cursor {
					cursor: default;
				}
				
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 120px;
					height: 120px;
					margin-left: -60px;
					margin-top: -60px;
				}
			}
		}

		@include breakpoint(small) {
			.partners-table .partner-box {
				width: 33%;

				img {
					width: 130px;
					height: 130px;
					margin-left: -65px;
					margin-top: -65px;
				}
			}
		}
		@include breakpoint(medium) {
			.partners-table .partner-box {
				width: 25%;

				img {
					width: 150px;
					height: 150px;
					margin-left: -75px;
					margin-top: -75px;
				}
			}
		}

		@media only screen and (min-width: 1700px) { 
			.partners-table .partner-box {
				width: 20%;
			}
		}
		
	}

	.partners_container {
		width: 500px;
		height: 400px;
		top: 100px;
		border: 1px red solid;
		margin-top: 200px;

		@include breakpoint(small) {
			width: 900px;
			margin-left: 350px;
		}
	}

	.text_column {
		margin-bottom: 50px;
		letter-spacing: 2px;

		padding-bottom: 120px;


		@include breakpoint(small) {
			width: 38%;
			margin-left: 5%;
		}
	}

	.bind_section, .mensen_section, .drama_section, .opdracht_section {
		img + h1 {
			margin-top: 35px;
		}
		h1 {
			font-size: 17px;
			line-height: 26px;
			color: #ffffff;
		}
		p {
			font-size: 17px;
			line-height: 26px;
			color: #999999;
			margin-top: 30px;
		}
	}

	.mensen_section {
		@include breakpoint(small) {
			float: left;
			width: 80%;
		}
	}

	.about-photo-holder {
		position: relative;
		// padding-top: 67%;
		padding-bottom: 120px;
		margin: 0;

		.preserve-ratio {
			padding-top: 66%;
		}

		.about-photo {
			position: absolute;
			top: 0;
			left: 0;
			@include transform(translateZ(1px));

			width: 100%;
			padding-top: 66%;

			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;
			}

            p {
                position: absolute;
                top:95%;
                padding-left:5%;
            }
			figure {
				position: absolute;
				top: 0;
				left: 0;

				margin: 0;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				
				@include transition(transform .8s ease);
			}

			&:hover {
				@include breakpoint(small) {
					.box:after {
						opacity: 1;
					}
					figure {
						@include transform(scale(1.08));
					}
				}
			}

			&.video_image {
				position: relative;

				.video-play {
					@include transition(top .3s ease-in-out);
				}

				&:hover {
					// .video-play {
					// 	top: 50%;
					// 	@include transition(top .4s cubic-bezier(.42,0,.42,1) .1s);
					// }
				}
			}

		}

		@include breakpoint(small) {
			width: 38%;
			padding-top: 0;

			// position: absolute;
			// top: 0;
			// right: 0;

			@include transform(translateZ(1px)); //fix glitch when scrolling
			position: fixed;
			top: 50%;
			right: 10%;

			margin: 0 5% 0 0;

			.about-photo {
				left: auto;
				right: 0;

				// width: 82%;
				padding-top: 66%;
			}
		}
	}


	.bind-gallery, .bind-video-player {
		div.box {
			overflow: hidden;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&:after {
				display: block;
				content: ' ';
				opacity: 0;

				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background-color: transparent !important;
				color: #fff;

				text-align: center;
				font-size: 24px;

				
				@include transition(visibility .5s $ease-out-quad, opacity .5s $ease-out-quad, padding-top .5s $ease-out-quad, background-position .5s $ease-out-quad);
			}
		} 
		figure {
			overflow: hidden;
			@include transform(translateZ(0));
		}
	}
	.bind-gallery {
		.box:after {
			@include retina-image('../img/gallery-plus', 27px 27px);
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}

	}
	.bind-video-player {
		figure {
			&:after {
				content: '';
			}
		}
	}

	#bindSection {
		.bind-gallery, .bind-video-player {
			figure {
				&:after {
					background-color: rgba(#f69c20, .95);
					color: #000;
				}
			}
		}
	}
	#opdrachtSection {
		.bind-gallery, .bind-video-player {
			figure {
				&:after {
					background-color: rgba(#fce899, .95);
					color: #000;
				}
			}
		}
	}
	#dramaSection {
		.bind-gallery, .bind-video-player {
			figure {
				&:after {
					background-color: rgba(#178fb8, .95);
				}
			}
		}
	}
	#mensenSection {
		.bind-gallery, .bind-video-player {
			figure {
				&:after {
					background-color: rgba(#f04e23, .95);
				}
			}
		}
	}
	#ruimteSection {
		.bind-gallery, .bind-video-player {
			figure {
				&:after {
					background-color: rgba(#76c59c, .95);
				}
			}
		}
	}

	.bind-video-player {
		.video-play {
			display: block;
			position: absolute;

			top: 100%;
			left: 50%;
			margin-top: -42px;
			margin-left: -42px;

			width: 84px;
			height: 84px;

			// background-image: url(../img/video_play_button.png);
		}
	}

	.invisible {
		display: none;
	}

	.about_footer {
		z-index: 999;

		position: fixed;
		left: 0;
		bottom: 0;

		width: 100%;
		height: 65px;

		cursor: pointer;
		background-color: #000;
		text-align: center;

		.about-footer-nav {
			display: none;
		}
		.about-footer-select {
			display: block;
		}

		@include breakpoint(small) {
			height: 80px;
			bottom: -80px;

			&.active {
				@include transition(bottom 0.5s $ease-out-quad);
				bottom: 0px;
			}

			.about-footer-nav {
				display: block;
			}
			.about-footer-select {
				display: none;
			}
		}
		@include breakpoint(large) {
			left: 10%;
			width: 80%;
		}


		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				float: left;
				width: 16.66%;
			}
		}

		.link {
			display: table;
			vertical-align: middle;

			width: 100%;
			height: 80px;

			text-align: center;
			padding-left: 2%;
			padding-right: 2%;

			@include transition(border-color .5s $ease-out-quad);
			span {
				display: table-cell;
				vertical-align: middle;
				width: 100%;

				@include font_proximanova(semibold);
				font-size: 11px;
				letter-spacing: 2px;
				text-transform: uppercase;
			}

			&.link_bind {
				border-top: 6px #7d4f10 solid;
				color: #f69c20;

				&.active, &:hover {
					border-color: #f69c20;
				}
			}

			&.link_opdracht {
				border-top: 6px #322e1f solid;
				color: #fce899;

				&.active, &:hover {
					border-color: #fce899;
				}
			}

			&.link_drama {
				border-top: 6px #051d25 solid;
				color: #178fb8;

				&.active, &:hover {
					border-color: #178fb8;
				}
			}

			&.link_mensen {
				border-top: 6px #301007 solid;
				color: #f04e23;

				&.active, &:hover {
					border-color: #f04e23;
				}
			}

			&.link_ruimte {
				border-top: 6px #18271f solid;
				color: #76c59c;

				&.active, &:hover {
					border-color: #76c59c;
				}
			}

			&.link_partners {
				border-top: 6px #30191e solid;
				color: #f27d96;

				&.active, &:hover {
					border-color: #f27d96;
				}
			}
		}
	}

	.about-footer-select {
		select {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;

			min-width: 100%;
			height: 65px;
			width: 100%;

			border: none;

			font-size:16px;
			font-weight: bold;
			background:none;
			border: none;
			color: #444;
			outline: none;
			opacity: 0;

			line-height:1.2;
		}

		.about-footer-currentselection {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;

			width: 100%;
			height: 100%;
			padding-top: 22px;

			border-top: 6px #067d4d solid;
			color: #067d4d;
			@include transition(border-color .5s $ease-out-quad);
			text-align: center;


			span {
				display: block;

				margin: 0 auto;
				width: 80%;

				@include font_proximanova(semibold);
				font-size: 11px;
				letter-spacing: 2px;
				text-transform: uppercase;
				text-align: left;

				@include retina-image('../img/select-arrow-up', 20px 12px);
				background-repeat: no-repeat;
				background-position: right 0;
			}
		}
	}
}