.page-template-template-news-php {
  #gridHolder {
    display: block !important; //override js setting for this page
    object svg {
      width: 100%;
    }
  }
  #content_json {
    z-index: 3; //over the grid
  }
}
#newsHolder {
  opacity: 1;

  @include breakpoint(small) {
      opacity: 0;
    
    &.active {
      opacity: 1; 
    } 
  }
}

body:not(.jsactive) {
  
  #newsHolder{
    opacity:0;

    -webkit-animation-fill-mode:forwards;
     -moz-animation-fill-mode:forwards;
    -ms-animation-fill-mode:forwards;
     -o-animation-fill-mode:forwards;
        animation-fill-mode:forwards;

    -moz-animation-name: fadeContent;
      -moz-animation-iteration-count: 1;
      -moz-animation-timing-function: ease-in;
      -moz-animation-duration: 0.5s;
      -moz-animation-delay: 5s;

      -webkit-animation-name: fadeContent;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 5s;

      animation-name: fadeContent;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
      animation-delay: 5s;
  }
}
@-moz-keyframes fadeContent {
      0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @-webkit-keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
.news {

  @include font_neutratext();
  //background-color: #202020;

  @include clearfix();

  img {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .newsImageDiv {
    position:relative;
    display:block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height:195px;
  }

  p {
    font-size: 16px;
    color: #999999;
  }

  .news-isotope {
    .grid-sizer {
      width: 100%;
    }
    .gutter-sizer {
      width: 0;
    }

    @include breakpoint(small) {
      .grid-sizer {
        width: 44%;
      }
      .gutter-sizer {
        width: 12%;
      }      
    }
  }

  .news_item {
    width:100%;
    margin-top: 90px;

    p {
      font-size: 17px;
    }
    h2 {
      font-size: 28px;
    }

    &.animate {
      @include transition(margin-top 1.5s $ease-out-quad, opacity 1.5s $ease-out-quad);
    }

    @include breakpoint(small) {
      width: 44%;
      margin-top: 150px;
      float: left;
      display: block;
      clear: both; // This stops the article bunching in spaces when the pattern of four articles repeats
      opacity: 0;

      &.in-view {
        @include keyframes(slideIn){
          from {
            @include transform(translate3d(0, 200px, 0));
          }
          to {
            @include transform(translate3d(0, 0, 0));
          }
        }
        @include animation(slideIn 1.5s $ease-out-quad);
        @include animation-fill-mode(forwards);

        margin-top: 150px;
        opacity: 1;

        // add margin on top on second column
        &:nth-child(4) {
          padding-top: 150px;
        }
      }
    }

  }
  @include breakpoint(small)
  {
    h2 {
      margin:0;
      margin-top: 35px;
      margin-bottom: 35px;
    }  
  }

  .news-content {
    position: relative;
    //background-color: #202020;
    color: #ffffff;
    padding-left: 10%;
    padding-right: 10%;
  }
  .news_text {
    margin-bottom: 20px;
    line-height: 26px;
  }

  .news_text p {
    margin: 0;
    position: relative;
    color: #999999;
    padding: 0;

    iframe, img {
      margin-bottom: 10px;
    }
  }

  .news_item iframe {
    width: 100%;
  }

  .view_project {
    height: 40px;
    display: inline-block;
  }

  .view_project a {
    text-decoration: none;
    color: #1d8bb3;
    font-size: 17px;
    vertical-align: middle;
    line-height: 40px;
  }
  .view_project img {
    margin-bottom: 5px;
    vertical-align: middle;
    width: 27px;
    height: 27px;
    margin-right: 12px;
  }
  .read_more {
    height: 40px;
    display: block;
    overflow: hidden;
    position: relative;
    width:126px;

    .more_text {
      display: inline-block;
      margin-left: 40px;
      margin-right: 12px;
      margin-top: 3px;
    }
    .icon {
      background-color:#000000;
      color:#f04e23;
      position: absolute;
      text-align:center;
      border-radius: 28px;
      padding-top: 6px;
      padding-left: 1px;
      width:28px;
      height:28px;
      display: inline-block;
      cursor: pointer;

    }
  }
  .read_more a {
    text-decoration: none;
    color: #f04e23;
    font-size: 17px;
    vertical-align: middle;
   // line-height: 40px;
   overflow: hidden;
   height: inherit;
   margin-right: -30px;
   position: absolute;
   width:160px;
   left:0px;
   @include transition(left 0.5s ease);
   &:hover{
      left:-30px;
   }
  }

  .read_more img {
    margin-bottom: 5px;
    vertical-align: middle;
    width: 27px;
    height: 27px;
    margin-right: 12px;
  }

  .right_footer_icons {
    float: right;
    display: inline;
    text-align: right;
  }

  .right_footer_icons a {
    text-decoration: none;
  }
  .right_footer_icons img {
    width: 27px;
    margin: 5px;
    height: 27px;
  }
}