
#contactHolder {
	display: block;
	background-color: rgba(1,5,2,0);
	width:100%;
	height:100%;
	position: fixed;
    overflow-y: hidden;
	top:0px;
	left:100%;
	z-index:100;

	@include transition(left 0.5s ease 0.3s, background-color 0.5s ease 0s);

   .wrapper{
    opacity: 0;
    @include transition(opacity 0.5s ease 0s);
  }

	&.active {
		left:0px;
		display: block;
		background-color: rgba(1,5,2,0.7);
		@include transition(left 0.5s $ease-out-quad, background-color 0.5s $ease-out-quad 0.3s);

        .wrapper {
            opacity: 1;
        }

        #contactFirst {
            .wrapper {
                @include transition(opacity 1s $ease-out-quad 0.6s);
            }
        }
        #contactSecond {
            .wrapper {
                @include transition(opacity 1s $ease-out-quad 1s);
            }
        }
	}

	#contactCloseHolder {
		width:40%;
        min-width: 750px;
		position: absolute;
		right:0px;
		clear:both;
		height:86px;
		background-color: #171717;


		#contactClose {
			background-color: #ffffff;
			border-radius: 30px;
			color: #f493a8;
			cursor: pointer;
			display: block;
			font-size: 18px;
			height: 30px;
			padding-top: 6px;
			position: absolute;
			right: 28px;
			text-align: center;
			top: 26px;
			width: 30px;
			
			@include transition(color 0.2s ease, background-color 0.2s ease);

			&:hover {
				background-color:#F493A8;
				color:#FFFFFF;
			}
		}
	}

  .icon {
    text-decoration: none;
  }

    #facebookIcon {
      position:absolute;
      bottom: 30px !important;
      margin-left : 100px;
      //background: url("../img/contact_facebook_icon.png") no-repeat !important;
      background-color:#272727;
      color:#939393;
      padding-top:6px;
      text-align:center;
      border-radius: 28px;

      width:28px !important;
      height:28px !important;
      display: block;
      cursor: pointer !important;

      @include transition(background-color 0.2s ease, color 0.2s ease);
                

      &:hover{
        color:#FFFFFF;
      }
    }
    #instagramIcon {
      position:absolute;
      bottom: 30px !important;
      margin-left : 50px;
      //background: url("../img/contact_facebook_icon.png") no-repeat !important;
      background-color:#272727;
      color:#939393;
      padding-top:6px;
      text-align:center;
      border-radius: 28px;

      width:28px !important;
      height:28px !important;
      display: block;
      cursor: pointer !important;

      @include transition(background-color 0.2s ease, color 0.2s ease);
                

      &:hover{
        color:#FFFFFF;
      }
    }
    #twitterIcon {
      position:absolute;
      bottom: 30px !important;
      margin-left : 100px;
      //background: url("../img/contact_twitter_icon.png") no-repeat;
      background-color:#272727;
      color:#939393;
      padding-top:6px;
      text-align:center;
      border-radius: 28px;
      width:28px;
      height:28px;
      display: block;
      cursor: pointer;

       @include transition(background-color 0.2s ease, color 0.2s ease);
                

      &:hover{
        background-color:#f27d96;
        color:#FFFFFF;
      }
    }
    #vimeoIcon {
      position:absolute;
      bottom: 30px !important;
      margin-left : 150px;
      //background: url("../img/contact_vimeo_icon.png") no-repeat;
      background-color:#272727;
      color:#939393;
      padding-top:6px;
      text-align:center;
      border-radius: 28px;
      width:28px;
      height:28px;
      display: block;
      cursor: pointer;

       @include transition(background-color 0.2s ease, color 0.2s ease);
                

      &:hover{
        color:#FFFFFF;
      }
    }
    #disclaimer_link {
      position: absolute;
      bottom: 35px !important;
      margin-left : 200px;
      font-size: 15px;
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @media only screen and (min-width: 750px) { 
        color: #939393;
      }
    }
    #getDirections {
      position:absolute;
      bottom: 76px !important;
      margin-left : 50px;
      //background: url("../img/contact_get_directions_icon.png") no-repeat;
      //background-position: 0 0;
      //padding-left:45px;
      height:26px;
      font-size:15px;
      color:#939393;
      padding-top: 0px;
      display: block;
      cursor: pointer;	
      text-decoration: none;
      @include transition(color 0.2s ease);
       .icon {
        background-color: #272727;
        border-radius: 28px;
        color: #939393;
        cursor: pointer;
        display: inline-block;
        height: 28px;
        margin-right: 10px;
        padding-left: 2px;
        padding-top: 6px;
        position: relative;
        text-align: center;

        width: 28px;

         @include transition(background-color 0.2s ease, color 0.2s ease);
                  

        
      }
      &:hover {
        color:#FFFFFF;
        .icon{
          background-color:#f27d96;
          color:#FFFFFF;
        }
      }
    }
	#contactFirst
	{
		width:20%;
    min-width:250px;
		float:right;
		height:inherit;
		display: block;
		background-color: #1b1b1b;
		margin-top: 86px;
		padding-top:40px;

		border-top: 4px solid #178fb8;
		border-right:1px solid #2b2b2b;

		img {
			width:60%;
			margin-left: 20%;
		}
	}

	#contactSecond
	{
    display: block;    
		width:20%;
    min-width:250px;
		float:right;
		height:inherit;
		
		background-color: #1b1b1b;
		margin-top: 86px;
		padding-top:40px;

		border-top: 4px solid #f27d96;
		img {
			width:60%;
			margin-left: 20%;
		}
	}

	#contactMap
	{
    position: absolute;
    top:86px;
    right: 0;
		width:20%;
        
		// float:right;
		height: calc(100% - 86px);
		// margin-top: 86px;
		display: block;
		// background-color: #1b1b1b;
	}

	p {
		color:#adadad;
		width:80%;
		margin-left: 20%;
		em {
			color:#FFFFFF;
			font-style: normal;
		}

    a {
      color:#adadad;
      text-decoration: none;
    }
	}

    @media only screen
    and (min-device-width : 320px)
    and (max-device-width : 568px) {

    	top:60px;
    	padding-bottom: 60px;
      
      #facebookIcon, #instagramIcon, #twitterIcon, #vimeoIcon {
      	display: none;
      }
      #contactHolder {
        height:auto !important;
        left:0% !important;
      }
      #contactFirst, #contactSecond {
        img, p {
          margin-left: 10%;
        }
      }
      overflow-y: scroll;
      body {
        overflow:scroll;
      }
      & > div {
        position:relative !important;
        width:100% !important;
        height: auto !important;
        min-height: 300px !important;
        margin-top: 0px !important;
        top:0px !important;
        padding: 10% 0 !important ;
        float:none !important;
        display:inline;
        float:none;
      }
      #getDirections {
        position: relative;
        bottom: auto !important;
        margin-left: 10%;
      }
      #contactClose {
        height:10% !important;
      }
      #contactSecond {
        display: none !important;    
      }
    }
}

body:not(.jsactive) {
  
  .contactHolder{
    opacity:0;

    -webkit-animation-fill-mode:forwards;
     -moz-animation-fill-mode:forwards;
    -ms-animation-fill-mode:forwards;
     -o-animation-fill-mode:forwards;
        animation-fill-mode:forwards;

    -moz-animation-name: fadeContent;
      -moz-animation-iteration-count: 1;
      -moz-animation-timing-function: ease-in;
      -moz-animation-duration: 0.5s;
      -moz-animation-delay: 5s;

      -webkit-animation-name: fadeContent;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-delay: 5s;

      animation-name: fadeContent;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
      animation-delay: 5s;
  }
}
@-moz-keyframes fadeContent {
      0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @-webkit-keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }
  @keyframes fadeContent {
     0% {
         opacity:0;
      }
      100% {
          opacity: 1;
      }
  }