
@include font-face('Neutratext', 'fonts/neutratext/neutratext-book-webfont', 300);
@include font-face('Neutratext', 'fonts/neutratext/NeutrafaceText-Demi', 600);
@include font-face('Proxima Nova', 'fonts/proximanova/proximanova-light-webfont', 300);
@include font-face('Proxima Nova', 'fonts/proximanova/proximanova-semibold-webfont', 600);
//@include font-face('Proxima Nova', 'fonts/proximanova/proximanova-bold-webfont', 700);


/*@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Proxima Nova';
        src: url('fonts/proximanova/proximanova-light-webfont.svg') format('svg');
        font-weight: 300;
    }
}*/

@mixin font_neutratext($weight:normal) {
    font-family: 'Neutratext', sans-serif;

    @if $weight == light {
        font-weight: 300;
    }
    @else if $weight == semibold {
        font-weight: 600;
    }
    //@else if $weight == bold {
    //    font-weight: bold;
    //}
    @else {
        font-weight: 300;
    }

}

@mixin font_proximanova($weight:normal) {
    font-family: 'Proxima Nova', sans-serif;

    @if $weight == light {
        font-weight: 300;
    }
    @else if $weight == semibold {
        font-weight: 600;
    }
    //@else if $weight == bold {
    //    font-weight: bold;
    //}
    @else {
        font-weight: 300;
    }
}